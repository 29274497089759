<template>
  <v-snackbar
    v-model="content.open"
    :timeout="content.timeout"
    :color="content.color"
  >
    <v-icon x-small left>{{ content.icon }}</v-icon
    >{{ content.text }}
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$store.getters.globalSnackbar;
    },
  },
};
</script>
