<template>
  <v-menu
    v-model="menu.open"
    offset-y
    left
    transition="slide-y-transition"
    :close-on-content-click="menu.closeOnContentClick"
    width="300px"
    max-width="300px"
  >
    <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            icon
            @click="onOpen"
            v-on="{ ...onTooltip, ...onMenu }"
            v-bind="attrsMenu"
            :loading="widgetLoading"
          >
            <v-icon>mdi-alarm-plus</v-icon>
          </v-btn>
        </template>
        <span>Log Time</span>
      </v-tooltip>
    </template>
    <v-card elevation="0" :loading="widgetLoading">
      <v-card-subtitle class="font-weight-bold">Log Time</v-card-subtitle>
      <v-divider></v-divider>
      <v-form @submit.prevent="saveTime" method="post">
        <v-card-text>
          <v-text-field
            label="Title"
            v-model="menu.fields.title"
            type="text"
            outlined
            dense
            hide-details
            class="mb-2"
            :error="menu.errors.hasOwnProperty('title')"
            :error-messages="menu.errors['title']"
          ></v-text-field>
          <v-row class="mb-2" no-gutters>
            <v-col cols="6" class="pr-1">
              <v-text-field
                label="Hours"
                v-model="menu.fields.hours"
                type="number"
                min="0"
                outlined
                dense
                hide-details
                :error="menu.errors.hasOwnProperty('hours')"
                :error-messages="menu.errors['hours']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-text-field
                label="Minutes"
                v-model="menu.fields.minutes"
                type="number"
                min="0"
                max="59"
                outlined
                dense
                hide-details
                :error="menu.errors.hasOwnProperty('minutes')"
                :error-messages="menu.errors['minutes']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-select
            v-on:change="send"
            label="Customer"
            v-model="menu.fields.customer_id"
            :items="customers"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-2"
            :placeholder="menu.fields.is_internal ? 'Is Internal' : null"
            :error="menu.errors.hasOwnProperty('customer_id')"
            :error-messages="menu.errors['customer_id']"
            @change="resetChunkSearch()"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleIsInternal">
                <v-list-item-action>
                  <v-icon>{{
                    menu.fields.is_internal
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Is Internal</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
              <div class="pa-3">
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="customerSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </template>
          </v-select>
          <v-select
            label="Work Block"
            v-model="menu.fields.chunk_id"
            :items="menu.fields.workChunks"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-2"
            :no-data-text="
              menu.fields.customer_id
                ? 'There are no work blocks available'
                : 'Please choose a customer'
            "
            :error="menu.errors.hasOwnProperty('chunk_id')"
            :error-messages="menu.errors['chunk_id']"
          >
            <template v-slot:prepend-item>
              <div class="pa-3" v-if="menu.fields.workChunks.length > 0">
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="chunkSearchTerm"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </template>
          </v-select>
          <v-menu
            v-model="menu.datePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Date"
                v-model="dateFormatted"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :error="menu.errors.hasOwnProperty('date')"
                :error-messages="menu.errors['date']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="menu.fields.date"
              @input="menu.datePicker = false"
            ></v-date-picker>
          </v-menu>
          <a
            href="#"
            class="text-decoration-none"
            v-if="!menu.showNotes"
            @click="menu.showNotes = true"
            >+ Add Notes</a
          >
          <v-textarea
            label="Notes"
            v-model="menu.fields.notes"
            rows="3"
            outlined
            dense
            hide-details
            auto-grow
            v-if="menu.showNotes"
            :error="menu.errors.hasOwnProperty('notes')"
            :error-messages="menu.errors['notes']"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="resetForm">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed type="submit" :loading="menu.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      customerSearchTerm: "",
      chunkSearchTerm: "",
      menu: {
        open: false,
        closeOnContentClick: false,
        showNotes: false,
        fields: {
          title: null,
          hours: 0,
          minutes: 0,
          is_internal: false,
          customer_id: null,
          chunk_id: null,
          date: new Date().toISOString().slice(0, 10),
          notes: null,
          workChunks: [],
        },
        datePicker: false,
        loading: false,
        errors: {},
      },
      widgetLoading: true,
    };
  },

  mounted() {
    this.loadCustomers();
  },

  computed: {
    customers() {
      let customers = this.$store.state.customers["customers"];
      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.customerSearchTerm.toLowerCase()) ||
            shortcode.includes(this.customerSearchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },
    dateFormatted() {
      return this.formatDate(this.menu.fields.date);
    },
    customerId() {
      return this.menu.fields.customer_id;
    },
    isInternal() {
      return this.menu.fields.is_internal;
    },
  },

  watch: {
    customerId(customerId) {
      if (customerId !== null && this.menu.fields.is_internal === true) {
        this.menu.fields.is_internal = false;
      }
    },
    isInternal(isInternal) {
      if (isInternal === true && this.menu.fields.customer_id !== null) {
        this.menu.fields.customer_id = null;
      }
    },
  },

  methods: {
    onOpen() {
      this.menu.fields.date = new Date().toISOString().slice(0, 10);
    },

    send: function () {
      const appId = this.$route.params.id;
      const customerId = this.menu.fields.is_internal
        ? null
        : this.menu.fields.customer_id;

      this.menu.loading = true;

      this.$store
        .dispatch("workload/loadCustomerChunks", {
          appId,
          customerId,
        })
        .then((res) => {
          this.menu.loading = false;
          this.menu.fields.workChunks = res.data.work_chunks;
        })
        .catch((err) => {
          this.menu.errors = err.response.data.errors;
          this.menu.loading = false;
        });
    },

    saveTime: function () {
      const appId = this.$route.params.id;
      const fields = this.menu.fields;

      this.menu.loading = true;

      this.$store
        .dispatch("workload/logTime", {
          appId,
          ...fields,
        })
        .then(() => {
          this.menu.open = false;
          this.resetForm();
          this.menu.loading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Time successfully logged!",
          });
        })
        .catch((err) => {
          this.menu.errors = err.response.data.errors;
          this.menu.loading = false;
        });
    },

    resetForm: function () {
      this.customerSearchTerm = "";
      this.chunkSearchTerm = "";
      this.menu.showNotes = false;
      this.menu.fields = {
        title: null,
        hours: 0,
        minutes: 0,
        is_internal: false,
        customer_id: null,
        chunk_id: null,
        date: new Date().toISOString().slice(0, 10),
        notes: null,
      };
      this.menu.errors = {};
    },

    loadCustomers: function () {
      const appId = this.$route.params.id;

      Promise.all([
        this.$store.dispatch("customers/loadCustomers", { appId }),
      ]).then(() => {
        this.widgetLoading = false;
      });
    },

    toggleIsInternal: function () {
      this.menu.fields.is_internal = !this.menu.fields.is_internal;
      if (this.menu.fields.is_internal == true) {
        this.send();
      } else {
        this.menu.fields.workChunks = [];
        this.menu.fields.customer_id = null;
      }
    },

    resetChunkSearch: function () {
      this.chunkSearchTerm = "";
    },
  },
};
</script>
