<template>
  <v-menu
    v-model="menu.open"
    offset-y
    left
    transition="slide-y-transition"
    :close-on-content-click="menu.closeOnContentClick"
    width="300px"
    max-width="300px"
  >
    <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            icon
            v-on="{ ...onTooltip, ...onMenu }"
            v-bind="attrsMenu"
            :loading="widgetLoading"
          >
            <v-icon>mdi-calendar-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Diary Entry</span>
      </v-tooltip>
    </template>
    <v-card elevation="0" :loading="widgetLoading">
      <v-card-subtitle class="font-weight-bold"
        >Add Diary Entry</v-card-subtitle
      >
      <v-divider></v-divider>
      <v-form @submit.prevent="saveWork" method="post">
        <v-card-text>
          <v-textarea
            label="Description"
            v-model="menu.fields.name"
            outlined
            dense
            hide-details
            class="mb-2"
            :error="menu.errors.hasOwnProperty('name')"
            :error-messages="menu.errors['name']"
          ></v-textarea>

          <v-select
            label="Customer"
            v-model="menu.fields.customer_id"
            :items="customers"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-2"
            :placeholder="menu.fields.is_internal ? 'Is Internal' : null"
            :error="menu.errors.hasOwnProperty('customer_id')"
            :error-messages="menu.errors['customer_id']"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleIsInternal">
                <v-list-item-action>
                  <v-icon>{{
                    menu.fields.is_internal
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Is Internal</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
              <div class="pa-3">
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="customerSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </template>
          </v-select>

          <v-menu
            v-model="menu.datePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Date"
                v-model="dateFormatted"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :error="menu.errors.hasOwnProperty('to_do')"
                :error-messages="menu.errors['to_do']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="menu.fields.to_do"
              @input="menu.datePicker = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="resetForm">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" depressed type="submit" :loading="menu.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      customerSearchTerm: "",
      menu: {
        open: false,
        closeOnContentClick: false,
        showNotes: false,
        fields: {
          name: null,
          status: "Closed",
          type: "Diary",
          locked: false,
          calendar: true,
          to_do: new Date().toISOString().slice(0, 10),
          team_lead: null,
          is_internal: false,
          customer_id: null,
          description: null,
          assigned: null,
          priority: 100,
          groups: [],
        },

        datePicker: false,
        loading: false,
        errors: {},
      },
      searchTermGroup: "",
      widgetLoading: true,
    };
  },

  mounted() {
    this.loadCustomers();
  },

  computed: {
    users() {
      return this.$store.state.team["teamMembers"];
    },

    currentUserMemberId() {
      return this.$store.getters["team/currentUserMemberId"];
    },

    filteredGroup: {
      get() {
        let groups = this.groups;
        if (this.searchTermGroup !== "" || this.searchTermGroup !== null) {
          groups = groups.filter((c) => {
            const name = c.name.toLowerCase();
            return name.includes(this.searchTermGroup.toLowerCase());
          });
        }
        return groups;
      },

      set(value) {
        return value;
      },
    },

    groups: {
      get() {
        let groups = this.$store.state.workload["groups"];

        return groups;
      },

      set(value) {
        return value;
      },
    },

    customers() {
      let customers = this.$store.state.customers["customers"];
      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.customerSearchTerm.toLowerCase()) ||
            shortcode.includes(this.customerSearchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },
    dateFormatted() {
      return this.formatDate(this.menu.fields.to_do);
    },
    customerId() {
      return this.menu.fields.customer_id;
    },
    isInternal() {
      return this.menu.fields.is_internal;
    },
  },

  watch: {
    groups(value) {
      this.groups = value;
    },

    customerId(customerId) {
      if (customerId !== null && this.menu.fields.is_internal === true) {
        this.menu.fields.is_internal = false;
      }
    },
    isInternal(isInternal) {
      if (isInternal === true && this.menu.fields.customer_id !== null) {
        this.menu.fields.customer_id = null;
      }
    },
  },

  methods: {
    send: function () {
      const appId = this.$route.params.id;
      const customerId = this.menu.fields.is_internal
        ? null
        : this.menu.fields.customer_id;

      this.menu.loading = true;

      this.$store
        .dispatch("workload/loadCustomerGroups", {
          appId,
          customerId,
        })
        .then((res) => {
          this.menu.loading = false;
          this.groups = res.data.groups;
        })
        .catch((err) => {
          this.menu.errors = err.response.data.errors;
          this.menu.loading = false;
        });
    },

    saveWork: function () {
      const appId = this.$route.params.id;

      let payload = {
        appId,
        fields: this.menu.fields,
      };

      this.menu.fields.team_lead = this.currentUserMemberId;

      this.menu.loading = true;

      this.$store
        .dispatch("workload/saveWorkChunk", {
          ...payload,
        })
        .then(() => {
          this.menu.open = false;
          this.resetForm();
          this.menu.loading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Add work successfully!",
          });
        })
        .catch((err) => {
          this.menu.errors = err.response.data.errors;
          this.menu.loading = false;
        });
    },

    resetForm: function () {
      this.customerSearchTerm = "";
      this.chunkSearchTerm = "";
      this.menu.showNotes = false;
      this.menu.fields = {
        name: null,
        status: "Closed",
        type: "Diary",
        to_do: null,
        locked: false,
        team_lead: null,
        is_internal: false,
        calendar: true,
        customer_id: null,
        description: null,
        assigned: null,
        groups: [],
      };
      this.menu.errors = {};
    },

    loadCustomers: function () {
      const appId = this.$route.params.id;

      Promise.all([
        this.$store.dispatch("customers/loadCustomers", { appId }),
        this.$store.dispatch("team/loadTeamMembers", { appId }),
        // this.$store.dispatch("workload/loadWorkGroups", { appId }),
      ]).then(() => {
        this.widgetLoading = false;
      });
    },

    toggleIsInternal: function () {
      this.menu.fields.is_internal = !this.menu.fields.is_internal;
      if (this.menu.fields.is_internal == true) {
        this.send();
      } else {
        this.menu.fields.groups = [];
        this.menu.fields.customer_id = null;
      }
    },

    resetChunkSearch: function () {
      this.searchTermGroup = "";
    },
  },
};
</script>
