<template>
  <v-overlay :value="idleTriggered">
    <v-card light max-width="350">
      <v-card-title class="headline">Session About To Expire</v-card-title>
      <v-card-text>
        You will be automatically logged out in
        <span
          class="font-weight-black"
        >{{ this.secondsRemaining }}</span>
        seconds unless you wish to continue this current session
      </v-card-text>
      <v-card-actions>
        <v-progress-circular :value="secondsRemaining" color="accent" rotate="-90"></v-progress-circular>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="accent"
          :disabled="buttonDisabled"
          v-on:click="continueSession"
        >Continue Session</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      idleTriggered: false,
      secondsRemaining: 100,
      timer: null,
      buttonDisabled: false,
    };
  },

  computed: {
    isIdle() {
      // return this.$store.state.idleVue.isIdle;
      return false;
    },
  },

  watch: {
    isIdle(now) {
      if (now && !this.idleTriggered && process.env.VUE_APP_STAGE !== "local") {
        this.idleTriggered = true;
      }
    },
    idleTriggered(newTrigger, oldTrigger) {
      if (
        newTrigger &&
        newTrigger !== oldTrigger &&
        process.env.VUE_APP_STAGE !== "local"
      ) {
        this.startCountdown();
      }
    },
  },

  methods: {
    continueSession: function () {
      this.idleTriggered = false;
      this.secondsRemaining = 100;
      clearInterval(this.timer);
    },
    startCountdown: function () {
      this.timer = setInterval(() => {
        if (this.secondsRemaining === 1) {
          this.secondsRemaining = 0;
          this.buttonDisabled = true;
          clearInterval(this.timer);
          this.$store.dispatch("logout").then(() => {
            this.idleTriggered = false;
          });
        } else {
          this.secondsRemaining = this.secondsRemaining - 1;
        }
      }, 1000);
    },
  },
};
</script>
